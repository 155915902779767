import request from '@/utils/request'


// 查询店铺分类列表
export function listShopCategory(query) {
  return request({
    url: '/shop/shop/category/list',
    method: 'get',
    params: query
  })
}

// 查询店铺分类分页
export function pageShopCategory(query) {
  return request({
    url: '/shop/shop/category/page',
    method: 'get',
    params: query
  })
}

// 查询店铺分类详细
export function getShopCategory(data) {
  return request({
    url: '/shop/shop/category/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺分类
export function addShopCategory(data) {
  return request({
    url: '/shop/shop/category/add',
    method: 'post',
    data: data
  })
}

// 修改店铺分类
export function updateShopCategory(data) {
  return request({
    url: '/shop/shop/category/edit',
    method: 'post',
    data: data
  })
}
export function updateVisible(data) {
  return request({
    url: '/shop/shop/category/visible',
    method: 'post',
    data: data
  })
}
// 删除店铺分类
export function delShopCategory(data) {
  return request({
    url: '/shop/shop/category/delete',
    method: 'post',
    data: data
  })
}
