import request from '@/utils/request'


// 查询主营行业（店铺）列表
export function listShopQc(query) {
  return request({
    url: '/shop/shop/qc/list',
    method: 'get',
    params: query
  })
}

// 查询主营行业（店铺）分页
export function pageShopQc(query) {
  return request({
    url: '/shop/shop/qc/page',
    method: 'get',
    params: query
  })
}

// 查询主营行业（店铺）详细
export function getShopQc(data) {
  return request({
    url: '/shop/shop/qc/detail',
    method: 'get',
    params: data
  })
}

// 新增主营行业（店铺）
export function addShopQc(data) {
  return request({
    url: '/shop/shop/qc/add',
    method: 'post',
    data: data
  })
}

// 修改主营行业（店铺）
export function updateShopQc(data) {
  return request({
    url: '/shop/shop/qc/edit',
    method: 'post',
    data: data
  })
}

// 删除主营行业（店铺）
export function delShopQc(data) {
  return request({
    url: '/shop/shop/qc/delete',
    method: 'post',
    data: data
  })
}
